export const WORKER_TYPES = {
  teacher: 'teacher',
  mentor: 'mentor',
  methodist: 'methodist',
  tutor: 'tutor',
};

export const teachers = [
  {
    id: 1,
    type: WORKER_TYPES.teacher,
    image: 'Майская Татьяна.webp',
    color: '#FBD277',
    description: '«„Всем хорошим во&nbsp;мне я&nbsp;обязан книгам“ — М.&nbsp;Горький»',
    name: 'Майская Татьяна',
    study: 'Магистр филологии (МГУ) <br> Опыт педагога: 8 лет',
  },
  {
    id: 2,
    type: WORKER_TYPES.teacher,
    image: 'Веденьева Марина.webp',
    color: '#61DDBC',
    description: '&laquo;Не&nbsp;бойся ошибиться, бойся не&nbsp;попробовать&raquo;',
    name: 'Веденьева Марина',
    study: 'Магистр лингвистики и&nbsp;межкультурных ком-ций (ЛГПУ), <br> Опыт педагога: 7&nbsp;лет',
  },
  {
    id: 3,
    type: WORKER_TYPES.teacher,
    image: 'Модебадзе Мардон.webp',
    color: '#B3A4EE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Очень важно уже в&nbsp;юности привить финансовую и&nbsp;правовую грамотность, это основа спокойной и&nbsp;свободной жизни каждого человека&raquo;',
    name: 'Модебадзе Мардон',
    study: 'Магистр права (НИУ ВШЭ), <br> Опыт педагога: 11&nbsp;лет',
  },
  {
    id: 4,
    type: WORKER_TYPES.teacher,
    image: 'Фролова Александра.webp',
    color: '#73B0F4',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;&bdquo;Русский язык в&nbsp;умелых руках и&nbsp;опытных устах красив, певуч, выразителен, гибок, послушен, ловок и&nbsp;вместителен&ldquo;&nbsp;&mdash; А.И. Куприн&raquo;',
    name: 'Фролова Александра',
    study: 'Магистр педагогики (МГОУ), <br> Опыт педагога: 7 лет',
  },
  {
    id: 5,
    type: WORKER_TYPES.teacher,
    image: 'Громов Александр.webp',
    color: '#F4D0B5',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Если вы&nbsp;дадите человеку программу, вы&nbsp;займете его на&nbsp;один день. Если вы&nbsp;научите его программировать, то&nbsp;займёте его на&nbsp;всю жизнь&raquo;',
    name: 'Громов Александр',
    study: 'Окончил МГУ и РГУ им. Губкина, <br> Опыт педагога: 9 лет',
  },
  {
    id: 6,
    type: WORKER_TYPES.teacher,
    image: 'Корнеева Маргарита.webp',
    color: '#B3A4EE',
    description: '&laquo;Учить язык&nbsp;&mdash; значит открыть новое окно в&nbsp;мир&raquo;',
    name: 'Корнеева Маргарита',
    study: 'Магистр лингвистики, МГПУ,<br> Опыт педагога: 6 лет',
  },
  {
    id: 7,
    type: WORKER_TYPES.teacher,
    image: 'Германович Олег.webp',
    color: '#9FE3FF',
    description: '&laquo;С&nbsp;хорошим учителем и&nbsp;математика не&nbsp;страшна&raquo;',
    name: 'Германович Олег',
    study: 'Магистр прикладной математики и&nbsp;физики (МГУ), <br> Опыт педагога: 7 лет',
  },
  {
    id: 8,
    type: WORKER_TYPES.teacher,
    image: 'Алимова Наиля.webp',
    color: '#B4DF80',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Чтобы быть хорошим педагогом нужно любить&nbsp;то, что преподаешь, и&nbsp;любить тех, кому преподаешь&raquo;',
    name: 'Алимова Наиля',
    study: 'Магистр естественных наук (СГСПУ), <br> Опыт педагога: 7 лет',
  },
  {
    id: 9,
    type: WORKER_TYPES.teacher,
    image: 'Тетяйкина Елена.webp',
    color: '#FBD277',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Педагог без любви к&nbsp;ребенку&nbsp;&mdash; все равно, что певец без голоса, музыкант без слуха, живописец без чувства цвета&raquo;',
    name: 'Тетяйкина Елена',
    study: 'Окончила МГПИ им. М.Е.Евсевьева <br> Опыт педагога: 6 лет',
  },
  {
    id: 10,
    type: WORKER_TYPES.teacher,
    image: 'Войнова Ирина.webp',
    color: '#61DDBC',
    description: '&laquo;Математика&nbsp;&mdash; это искусство видеть возможности&raquo;',
    name: 'Войнова Ирина',
    study: 'Окончила СГАУ&nbsp;им. академика С.П.Королёва,<br> Опыт педагога: 7 лет',
  },
  {
    id: 11,
    type: WORKER_TYPES.teacher,
    image: 'Никитенко Анастасия.webp',
    color: '#B3A4EE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Всё можно пережить, если подобрать нужную песню&raquo;',
    name: 'Никитенко Анастасия',
    // eslint-disable-next-line max-len,vue/max-len
    study: 'Окончила Калужский музыкальный колледж и&nbsp;Институт современного образования,<br> Опыт педагога: 11 лет',
  },
  {
    id: 12,
    type: WORKER_TYPES.teacher,
    image: 'Котова Анна.webp',
    color: '#B4DF80',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Умею и&nbsp;научу создавать крутые работы! <br> И&nbsp;помним: &bdquo;в&nbsp;Гугле есть все, кроме твоего опыта&ldquo;&raquo;',
    name: 'Котова Анна',
    study: 'Окончила МПГУ, школу акварели С.Андрияки, <br> Опыт педагога: 7 лет',
  },
  {
    id: 13,
    type: WORKER_TYPES.teacher,
    image: 'Полякова Ольга.webp',
    color: '#B4DF80',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Маленькими шагами к&nbsp;большой цели!&raquo;',
    name: 'Полякова Ольга',
    study: 'Окончила МГОПУ (бывш. МОПИ им.&nbsp;Н.&nbsp;К.&nbsp;Крупской),<br> Опыт педагога: 6&nbsp;лет',
  },
  {
    id: 14,
    type: WORKER_TYPES.teacher,
    image: 'Камалетдинова Галия.webp',
    color: '#FB836F',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Спорт&nbsp;&mdash; это секретное оружие, которое всегда с&nbsp;тобой&raquo;',
    name: 'Камалетдинова Галия',
    study: 'Специализации &laquo;Физическая культура и&nbsp;спорт&raquo; (ПГУ),<br> Опыт педагога: 3&nbsp;года',
  },
];

export const mentors = [
  {
    id: 15,
    type: WORKER_TYPES.mentor,
    image: 'Аношина Анастасия.webp',
    color: '#F199CE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Умею найти подход к&nbsp;любому ученику, объяснить сложное простым языком и&nbsp;всегда остаюсь на&nbsp;связи, чтобы в&nbsp;любое время дня и&nbsp;ночи ответить на&nbsp;вопросы&raquo;',
    name: 'Аношина Анастасия',
    study: 'Окончила МПГУ, «Математика и современный образовательные технологии» ',
  },
  {
    id: 16,
    type: WORKER_TYPES.mentor,
    image: 'Разина Лика.webp',
    color: '#9FE3FF',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Мне интересно вызывать у&nbsp;детей интерес к&nbsp;знаниям и&nbsp;воспитывать у&nbsp;них любознательность и&nbsp;веру в&nbsp;себя&raquo;',
    name: 'Разина Лика',
    study: 'Выпускница РТУ МИРЭА и МГТУ им.Баумана',
  },
  {
    id: 17,
    type: WORKER_TYPES.mentor,
    image: 'Ефименко Татьяна.webp',
    color: '#FB836F',
    description: '&laquo;Быть учителем&nbsp;&mdash; уметь зажигать сердца&raquo;',
    name: 'Ефименко Татьяна',
    study: 'Учитель года Таганрога-2020, победитель конкурса лучших учителей РФ-2022 (ПНПО)',
  },
  {
    id: 18,
    type: WORKER_TYPES.mentor,
    image: 'Артемова Людмила.webp',
    color: '#FBD277',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;К&nbsp;работе&nbsp;&mdash; творчески, к&nbsp;детям&nbsp;&mdash; с&nbsp;любовью&raquo;',
    name: 'Артемова Людмила',
    study: 'Окончила СГУ имени Н.Г. Чернышевского, педагог высшей категории',
  },
];

export const methodists = [
  {
    id: 19,
    type: WORKER_TYPES.methodist,
    image: 'Боженова Наталья.webp',
    color: '#FBD277',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Считаю, что секрет хорошего урока в&nbsp;балансе структурированной информации, эмоций, креатива и&nbsp;системы упражнений&raquo;',
    name: 'Боженова Наталья',
    study: 'Магистр по русскому языку и&nbsp;литературе (МПГУ) <br> Опыт педагога: 10 лет',
  },
  {
    id: 20,
    type: WORKER_TYPES.methodist,
    image: 'Сопченко Анна.webp',
    color: '#B3A4EE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Любите&nbsp;то, что делаете и&nbsp;щедро дарите эту любовь тому, для кого это делаете. С&nbsp;удовольствием учатся только там, где с&nbsp;удовольствием учат!&raquo;',
    name: 'Сопченко Анна',
    // eslint-disable-next-line max-len,vue/max-len
    study: 'Методолог онлайн-курсов, разработчик авторских уроков по обществу, экономике и праву   <br> Опыт педагога: 7 лет',
  },
  {
    id: 21,
    type: WORKER_TYPES.methodist,
    image: 'Карташева Анна.webp',
    color: '#73B0F4',
    description: '&laquo;Обучение успешно, когда оно проходит в&nbsp;игре&raquo;',
    name: 'Карташева Анна',
    study: 'Дипломы по специальностям «Лингвистика»  и «Методика преподавания»<br> Опыт педагога: 8 лет',
  },
  {
    id: 22,
    type: WORKER_TYPES.methodist,
    image: 'Кашеварова Инга.webp',
    color: '#FBD277',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;&bdquo;Учиться надо всю жизнь, до&nbsp;последнего вздоха&ldquo;&nbsp;&mdash; Сюнь-цзы&raquo;',
    name: 'Кашеварова Инга',
    // eslint-disable-next-line max-len,vue/max-len
    study: 'Специальность «Учитель английского и немецкого языков», написала кандидатскую по лексикографии, TKT, Tesol/Tefl <br> Опыт педагога: 17 лет',
  },
  {
    id: 23,
    type: WORKER_TYPES.methodist,
    image: 'Давыдова Алена.webp',
    color: '#9FE3FF',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Учитель&nbsp;&mdash; это фотон, который несёт свет и&nbsp;не&nbsp;существует в&nbsp;покое&raquo;',
    name: 'Давыдова Алена',
    // eslint-disable-next-line max-len,vue/max-len
    study: 'Выпускница ПГСГА, победитель многих конкурсов педагогического мастерства  городского, областного и федерального уровней <br> Опыт педагога: 12 лет',
  },
];

export const tutors = [
  {
    id: 24,
    type: WORKER_TYPES.tutor,
    image: 'Ведьманова Татьяна.webp',
    color: '#FBD277',
    description: '&laquo;В&nbsp;своей работе использую дружеский подход&raquo;',
    name: 'Ведьманова Татьяна',
    study: 'Специализация: «Преподавание в начальных классах» и «Психолого-педагогическое»',
  },
  {
    id: 25,
    type: WORKER_TYPES.tutor,
    image: 'Ермакова Наталья.webp',
    color: '#B3A4EE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Хороший педагог прежде всего должен любить свою работу, поскольку иначе ему будет сложно справиться с&nbsp;возложенными на&nbsp;него задачами по&nbsp;воспитанию и&nbsp;образованию подрастающего поколения, ну&nbsp;а&nbsp;мы&nbsp;в&nbsp;этом им&nbsp;поможем&raquo;',
    name: 'Ермакова Наталья',
    study: 'Специализация: «Коррекционный педагог младших классов» и «Социальная педагогика и психология»',
  },
  {
    id: 26,
    type: WORKER_TYPES.tutor,
    image: 'Низамбиева Алина.webp',
    color: '#F199CE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Для меня самое главное&nbsp;&mdash; это последовательная работа в&nbsp;команде с&nbsp;родителем и&nbsp;ребенком для достижения поставленных целей&raquo;',
    name: 'Низамбиева Алина',
    study: 'Выпускница МГПУ «Детская психология»',
  },
  // {
  //   id: 27,
  //   type: WORKER_TYPES.tutor,
  //   image: 'Соляных Антон.webp',
  //   color: '#9FE3FF',
  // eslint-disable-next-line max-len,vue/max-len
  //   description: '&laquo;4-й год работаю с&nbsp;детьми и&nbsp;подростками. Могу с&nbsp;уверенностью сказать, что при желании можно найти индивидуальный подход к&nbsp;каждому&raquo;',
  //   name: 'Соляных Антон',
  //   study: 'Руководитель команды наставников Магистр педагогики и&nbsp;психологии (НИУ ВШЭ)',
  // },
  {
    id: 28,
    type: WORKER_TYPES.tutor,
    image: 'Смирнова Елена.webp',
    color: '#B3A4EE',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;Я&nbsp;хочу показать детям, что учёба может быть лёгкой и&nbsp;интересной! Со&nbsp;мной они узнают, что классный руководитель не&nbsp;только их&nbsp;наставник в&nbsp;обучении, но&nbsp;и&nbsp;друг&raquo;',
    name: 'Смирнова Елена',
    study: 'Магистр МГПУ «Психология»',
  },
  {
    id: 29,
    type: WORKER_TYPES.tutor,
    image: 'Ялова Анжелика.webp',
    color: '#B4DF80',
    // eslint-disable-next-line max-len,vue/max-len
    description: '&laquo;В&nbsp;работе создаю безопасную и&nbsp;принимающую среду для каждого ребенка, Стремлюсь к&nbsp;постоянному совершенствованию и&nbsp;ценю обратную связь для улучшения качества обучения&raquo;',
    name: 'Ялова Анжелика',
    study: 'Магистр по детской и взрослой психологии. «Психолог-педагог»',
  },
];

export default {
  WORKER_TYPES,
  teachers,
  mentors,
  methodists,
  tutors,
};
