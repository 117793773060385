<template>
  <div class="workers">
    <div class="v2-container-small">
      <h2>
        Развивайся, задавай вопросы, раскрой свой потенциал,
        <span>
          <b>будь&nbsp;собой</b>
        </span>
      </h2>
      <h4 class="text-secondary">
        ...а мы&nbsp;создадим бережную атмосферу, в&nbsp;которой легко это сделать
      </h4>
    </div>

    <div class="workers__btns">
      <button
        v-for="btn in buttons"
        :key="btn.type"
        :class="['m-btn', 'workers__btn', { 'workers__btn--active': activeType === btn.type }]"
        @click="activeType = btn.type"
      >
        {{ btn.text }}
      </button>
    </div>

    <custom-scroll-slider
      class="workers__items"
      :items="filteredWorkers"
      :container-width="1240"
      :gap="isTablet ? 16 : isLaptop ? 24 : 40"
    >
      <template #item="{ slide }">
        <div :class="['workers__item', `workers__item--${slide.type}`]">
          <div class="workers__item_card">
            <img
              loading="lazy"
              :src="`/v2/school/workers/${slide.image}`"
              :alt="slide.name"
              class="workers__item_img"
            >

            <div
              :class="['workers__item_text', {
                'workers__item_text--opened': opened.includes(slide.id),
              }]"
              :style="{ backgroundColor: slide.color }"
            >
              <p
                class="text-primary"
                v-html="slide.description"
              />
            </div>

            <button
              class="m-btn workers__item_btn"
              @click="toggleSlide(slide)"
            >
              <img
                v-show="opened.includes(slide.id)"
                loading="lazy"
                src="/v2/common/close.svg"
                alt="close info"
                class="workers__item_btn_close"
              >
              <img
                v-show="!opened.includes(slide.id)"
                loading="lazy"
                src="/v2/common/info.svg"
                alt="info"
                class="workers__item_btn_info"
              >
            </button>

            <div class="workers__item_cheap">
              <img
                v-if="slide.type === WORKER_TYPES.teacher"
                loading="lazy"
                :src="`/v2/school/workers/teacher-chips${isTablet?'-mob':''}/${slide.name}.webp`"
                :alt="slide.type"
              >
              <template v-else>
                <img
                  loading="lazy"
                  :src="`/v2/school/workers/${slide.type}-cheap.webp`"
                  :alt="slide.type"
                >
                <span>{{ typeText[slide.type] }}</span>
              </template>
            </div>
          </div>

          <h5>{{ slide.name }}</h5>
          <h6 v-html="slide.study" />
        </div>
      </template>
    </custom-scroll-slider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CustomScrollSlider from '~/components/v2/common/CustomScrollSlider.vue';

import {
  WORKER_TYPES, teachers, mentors, methodists, tutors,
} from './WorkersData';

export default {
  name: 'SchoolWorkers',
  components: { CustomScrollSlider },
  data: () => ({
    activeType: 'all',
    buttons: [
      { text: 'Все', type: 'all' },
      { text: 'Педагоги', type: WORKER_TYPES.teacher },
      { text: 'Команда заботы', type: 'employee' },
    ],

    opened: [],
    WORKER_TYPES,
  }),

  computed: {
    ...mapGetters({
      isTablet: 'isTablet',
      isLaptop: 'isLaptop',
    }),

    // вынес в отдельный computed, чтобы каждый раз заново не перемешивать
    allWorkersShuffle() {
      return this.shuffleArray([...teachers, ...mentors, ...methodists, ...tutors]);
    },
    allEmployeesShuffle() {
      return this.shuffleArray([...mentors, ...tutors]);
    },
    allTeachersShuffle() {
      return this.shuffleArray([...teachers, ...methodists]);
    },

    filteredWorkers() {
      if (this.activeType === 'all') return this.allWorkersShuffle;
      if (this.activeType === WORKER_TYPES.teacher) return this.allTeachersShuffle;
      return this.allEmployeesShuffle;
    },
    typeText() {
      return {
        [WORKER_TYPES.teacher]: 'учитель',
        [WORKER_TYPES.mentor]: 'наставник',
        [WORKER_TYPES.methodist]: 'методист',
        [WORKER_TYPES.tutor]: 'куратор',
      };
    },
  },

  methods: {
    shuffleArray(list) {
      const copiedList = [...list];

      for (let i = copiedList.length - 1; i > 0; i -= 1) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        const tempListItem = copiedList[i];
        copiedList[i] = copiedList[randomIndex];
        copiedList[randomIndex] = tempListItem;
      }

      return copiedList;
    },

    toggleSlide(slide) {
      if (this.opened.includes(slide.id)) {
        this.opened = this.opened.filter((i) => i !== slide.id);
      } else {
        this.opened.push(slide.id);
      }
    },
  },
};
</script>

<style scoped lang="scss">

.workers {
  max-width: 100vw;
  overflow: hidden;
  color: $black;

  h2 {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.35px;
    text-transform: uppercase;
    margin: 0 auto 20px;
    max-width: 915px;

    @include media-down(laptop) {
      font-size: 36px;
    }

    @include media-down(tablet) {
      font-size: 26px;
      letter-spacing: -0.52px;
      margin-bottom: 12px;
    }

    span {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 37px;
        background-color: #FBD277;

        @include media-down(tablet) {
          left: -2px;

          width: 102%;
          height: 21px;
        }
      }

      b {
        position: relative;
        z-index: 2;
      }
    }
  }

  .text-secondary {
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    margin: 0 auto 60px;
    max-width: 730px;

    @include media-down(laptop) {
      font-size: 26px;
      max-width: 550px;
    }

    @include media-down(tablet) {
      font-size: 20px;
      margin-bottom: 32px;
    }
  }

  .workers__btns {
    display: flex;
    justify-content: center;
    gap: 2px;
    overflow-x: auto;
  }

  .workers__btn {
    padding: 8px 18px 12px;
    border-radius: 50px;
    border: 2px dashed $black;
    color: $black;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    flex-shrink: 0;
    display: block;

    transition-duration: 400ms;
    transition-timing-function: ease-out;

    @media (hover: hover) {
      &:hover:not(.workers__btn--active) {
        border: 2px solid $black;
      }
    }

    &.workers__btn--active {
      border-style: solid;
      background-color: #FBD277;
    }
  }

  .workers__items {
    display: flex;
    gap: 40px;
    padding-bottom: 1px;
    padding-top: 40px;

    overflow: auto;

    @include media-down(laptop) {
      padding-top: 30px;
      gap: 24px;
    }

    @include media-down(tablet) {
      padding-top: 20px;
      gap: 16px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .workers__item {
    position: relative;
    width: 295px;
    flex-shrink: 0;
    color: $black;
    user-select: none;

    .workers__item_card  {
      position: relative;
      height: 406px;
      margin-bottom: 27px;

      @include media-down(tablet) {
        height: 387px;
        margin-bottom: 22px;
      }
    }

    .workers__item_img {
      height: 100%;
      width: calc(100% - 7px);
      object-fit: cover;
      object-position: center;

      border-radius: 20px;
      border: 2px solid $black;
      background-color: $white-color;
      position: relative;
      z-index: 2;

      pointer-events: none;
    }

    .workers__item_text {
      border-radius: 20px;
      border: 2px solid $black;
      padding: 77px 12px 12px;
      position: absolute;
      left: 7px;
      top: 7px;
      height: 100%;
      width: calc(100% - 7px);

      @include media-down(tablet) {
        padding: 68px 14px 14px;
      }

      p {
        letter-spacing: -0.2px;

        @include media-down(tablet) {
          letter-spacing: 0;
        }
      }

      &.workers__item_text--opened {
        z-index: 3;
      }
    }

    .workers__item_btn {
      position: absolute;
      z-index: 4;
      width: 44px;
      height: 44px;
      left: 20px;
      top: 20px;
      background-color: $white-color;
      border-radius: 50%;

      @include media-down(tablet) {
        left: 16px;
        top: 16px;
      }

      &:hover {
        box-shadow: 2px 2px 0 0 $black;
        transform: translate(-2px, -2px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .workers__item_cheap  {
      font-size: 30px;
      font-weight: 600;
      line-height: 90%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      z-index: 4;
      width: fit-content;

      @include media-down(tablet) {
        font-size: 20px;
        line-height: 100%;
      }

      span {
        position: relative;
        z-index: 2;
      }

      img {
        left: 0;
        top: 0;
        object-fit: contain;
        object-position: center;
      }
    }

    h5 {
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 30px;
      font-weight: 600;
      line-height: 90%;
      margin-bottom: 10px;

      @include media-down(tablet) {
        font-size: 25px;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 8px;
      }
    }

    h6 {
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 600;
      line-height: 100%;

      @include media-down(tablet) {
        font-size: 16px;
        line-height: 120%;
      }
    }

    &.workers__item--teacher .workers__item_cheap {
      max-width: 200px;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.workers__item--mentor .workers__item_cheap {
      padding: 13px 16px 19px 20px;
      transform: rotate(7deg);
      bottom: -17px;
      left: -15px;

      @include media-down(tablet) {
        padding: 8.5px 13.5px 12.5px 12.5px;
        bottom: -9px;
        left: -5px;
      }

      img {
        position: absolute;
        max-width: 100%;
      }
    }

    &.workers__item--methodist .workers__item_cheap {
      padding: 14px 12px 19px;
      transform: rotate(-7deg);
      bottom: -21px;
      right: -21px;

      @include media-down(tablet) {
        padding: 9px 7px 13px 9px;
        bottom: -9px;
        right: -2px;
      }

      img {
        position: absolute;
        max-width: 100%;
      }
    }

    &.workers__item--tutor .workers__item_cheap {
      padding: 15px 18px 19px;
      transform: rotate(-3deg);
      bottom: -21px;
      left: -11px;

      @include media-down(tablet) {
        padding: 8px 14.5px 19px ;
        bottom: -13px;
        left: -6px;
      }

      img {
        position: absolute;
        max-width: 100%;
      }
    }
  }
}

</style>
